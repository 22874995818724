import React from 'react';
import * as styles from './Steps.module.scss';
import clsx from 'clsx';
import parse from 'html-react-parser';
import Title from '../Title/Title';

function Step({ data }) {
    return (
        <li className={styles.step}>
            <div className={styles.circleBorder}>
                <span>{data.order_number.text}</span>
            </div>
            <div className={styles.stepContent}>
                <div className={styles.stepHeadline}>{parse(data.headline.html)}</div>
                <div className={styles.stepSubhead}>{parse(data.subhead.html)}</div>
            </div>
            <img src={data.image.url} alt="" />
        </li>
    )
}

export default function Steps({ data }) {
    return (
        <section className={clsx(styles.steps, data.css_class)}>
            <div className={clsx('section-wrapper')}>
                <div className={styles.headline}>
                  <Title className={'add-spacing'}>{parse(data.headline.html)}</Title>
                </div>
                <ol className={styles.content}>
                    {data.list.map((item, index) => (<Step data={item} key={index} />))}
                </ol>
            </div>
        </section>
    )
}