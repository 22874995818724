import React from 'react';import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { graphql } from 'gatsby';
import clsx from 'clsx';
import Layout from '../components/layout';
import SearchEngineOpt from '../components/SearchEngineOpt/SearchEngineOpt';
import Hero from '../components/Hero/Hero';
import ImageText from '../components/ImageText/ImageText'
import Steps from '../components/Steps/Steps';
import TextSection from '../components/TextSection/TextSection';
import VideoPlayer from '../components/VideoPlayer/VideoPlayer';

export default function SciencePage({data}) {
  return (
    <Layout>
      <div className="page">
        <Hero className={clsx(data.page.data.header_css_class.text, 'hero-science')} text={data.page.data.header_text.html} image={data.page.data.header_image} />
        <Breadcrumb className={data.page.data.breadcrumb_css_class && data.page.data.breadcrumb_css_class.text} list={data.page.data.breadcrumb}/>
        <TextSection text={[data.page.data.video_headline.html, data.page.data.video_subhead.html]} className="black-title"/>
        <VideoPlayer bgImage={data.page.data.video_poster_image.url} video={data.page.data.video_url.text}/>
        {data.page.data.sections.map((section, index) => (
          <ImageText key={index} data={section} />
        ))}
        <Steps data={{ headline: data.page.data.ol_headline, list: data.page.data.ol }}/>
      </div>
    </Layout>
  )}

  export const Head = ({ data }) => (
    <SearchEngineOpt
      title={data.page.seo.title && data.page.seo.title.text}
      description={data.page.seo.description && data.page.seo.description.text}
      image={data.page.seo.image && data.page.seo.image.url}
      keywords={''} />
  )

export const query = graphql`
query {
  page: prismicScience {
    uid
    seo: data {
      title {
        text
      }
      description {
        text
      }
      image {
        url
      }
    }
    data {
      header_text {
        html
      }
      header_image {
        url
        alt
      }
      header_css_class {
        text
      }
      breadcrumb {
        link_text {
          text
        }
        link_url {
          target
          slug
          link_type
          url
        }
      }
      breadcrumb_css_class {
        text
      }
			video_headline {
        html
      }
      video_subhead {
        html
      }
      video_url {
        text
      }
      video_poster_image {
      	url
      }
      sections {
        headline {
          html
        }
        paragraph {
          html
        }
        cta_text {
          text
        }
        cta_url {
          target
          slug
          link_type
          url
        }
        css_class {
          text
        }
        image_css_class {
          text
        }
        background_image {
          url
        }
        image {
          alt
          url
        }
        component_type {
          text
        }
        height_max {
          text
        }
      }
      ol_headline {
        html
      }
      ol {
        headline {
          html
        }
        subhead {
          html
        }
        order_number {
          text
        }
        css_class {
          text
        }
        image {
          url
          alt
        }
      }
    }
  }
}
`
